import React, { useEffect, useRef } from "react";

export default function ObserverTarget({ fetchData }) {
  const ObserverTargetRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          typeof fetchData === "function" && fetchData();
        }
      },
      { threshold: 1 }
    );

    if (ObserverTargetRef.current) {
      observer.observe(ObserverTargetRef.current);
    }

    return () => {
      if (ObserverTargetRef.current) {
        observer.unobserve(ObserverTargetRef.current);
      }
    };
  }, [ObserverTargetRef]);

  return <div ref={ObserverTargetRef}></div>;
}
