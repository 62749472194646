import React from "react";
import { Button } from "react-bootstrap";
import { FaEdit, FaEye } from "react-icons/fa";

export default function ShowButton({ onClick }) {
  return (
    <Button
      size="sm"
      variant="primary"
      className="m-1 box-shadow-none"
      title="Edit Item"
      onClick={onClick}
    >
      <FaEye size={10} />
    </Button>
  );
}
