import React from "react";
import { Card, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function TileItem({
  isLoading,
  icon,
  iconContainerClassName,
  title,
  subTitle,
  data,
  to,
}) {
  return isLoading ? (
    <Placeholder animation="glow" className="w-100">
      <Placeholder
        style={{
          height: 120,
        }}
        className="w-100 rounded box-shadow"
      />
    </Placeholder>
  ) : (
    <Card className="border-0 box-shadow flex-fill rounded">
      <Link to={to}>
        <Card.Body>
          <div className="d-flex flex-row gap-2 align-items-center mb-2">
            <div className={`px-2 py-1 rounded ${iconContainerClassName}`}>
              {icon}
            </div>
            <div>
              <p className="m-0 fw-bold h6 m-0 text-dark">{title}</p>
              <small className="text-muted">{subTitle}</small>
            </div>
          </div>

          <p className="small text-muted fw-bold h1 mt-3">{data}</p>
        </Card.Body>
      </Link>
    </Card>
  );
}
