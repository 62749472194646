import React from "react";
import { Placeholder } from "react-bootstrap";

export default function TableLineItemLoading({ colspan, row }) {
  return Array.from(Array(row ?? 1).keys())?.map((index) => (
    <tr key={index}>
      <th colSpan={colspan ?? 1}>
        <Placeholder animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
      </th>
    </tr>
  ));
}
