import React from 'react'
import { Alert } from 'react-bootstrap'

const FormError = ({error}) => {
  return (
    <Alert variant='danger' className='mt-3'>
      <ul className='m-0 ps-3'>
        {
          error.map((message, key) => <li className='mb-1 small' key={key}>{message}</li>)
        }
      </ul>
    </Alert>
  )
}

export default FormError