import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";
import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

export default function QrcodeDownloadButton({ url, fileName }) {
  const [showDownloadWindow, setShowDownloadWindow] = useState(false);
  const qrRef = useRef(null);

  const handleDownloadScreen = async (e) => {
    e.preventDefault();
    if (typeof url !== "string") {
      throw new Error(
        "URL prop value is invalid, please provide a valid string"
      );
    } else if (typeof fileName !== "string") {
      throw new Error(
        "File name prop value is invalid, please provide a valid string"
      );
    }

    setShowDownloadWindow(!showDownloadWindow);
    setTimeout(() => download(fileName), 1000);
  };

  const download = async (fileName) => {
    const canvas = await html2canvas(qrRef.current);
    const image = canvas.toDataURL("image/png", 1.0);
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = image;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    setShowDownloadWindow(false);
  };

  return (
    <>
      {showDownloadWindow && (
        <div className="qrDownloadWindow d-flex flex-column justify-content-center align-items-center">
          <div ref={qrRef}>
            <QRCodeSVG
              size={280}
              bgColor="#fff"
              fgColor="#662d91"
              value={url}
            />
          </div>
          <div className="mt-3 mb-1">Downloading</div>
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <Button
        className="box-shadow-none text-uppercase"
        size="sm"
        variant="success"
        title="Download QR Code"
        onClick={handleDownloadScreen}
      >
        <div className="d-flex gap-1 align-items-center">
          <FaDownload /> <span>QR Code</span>
        </div>
      </Button>
    </>
  );
}
