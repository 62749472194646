import React from "react";
import { Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

export default function EditButton({ onClick }) {
  return (
    <Button
      size="sm"
      variant="warning"
      className="m-1 box-shadow-none"
      title="Edit Item"
      onClick={onClick}
    >
      <FaEdit size={10} />
    </Button>
  );
}
