import React from "react";

export default function ThemeButton({ className, variant, children, ...rest }) {
  return (
    <button
      className={`${
        variant === "light"
          ? "bg-light text-dark border border-secondary"
          : "app-custom-btn-1"
      } rounded  p-2 ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
