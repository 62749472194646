import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Container, Table, Offcanvas } from "react-bootstrap";
import ComponentSearch from "../../../../components/componentSearch/ComponentSearch";
import LoadingUI from "../../../../components/LoadingUI/LoadingUI";
import TableLineItemLoading from "../../../../components/table-line-item-loading/TableLineItemLoading";
import ObserverTarget from "../../../../components/observerTarget/ObserverTarget";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ShowButton from "../../../../components/buttons/showButton/ShowButton";
import InvoicePreview from "../../../../components/invoice/InvoicePreview";

const Invoice = () => {
  const [invoicesData, setInvoicesData] = useState(undefined);
  const [activeInvoice, setActiveInvoice] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const apiCallDelay = useRef(undefined);

  const handleSearch = (e) => {
    let query = e.target.value;
    setInvoicesData(undefined);
    setSearching(true);
    setSearchKey(query);
    apiCallDelay?.current && clearTimeout(apiCallDelay.current);
    apiCallDelay.current = setTimeout(() => {
      getItems(1, {
        invoice_number: query,
      });
    }, 1500);
  };

  const handleShow = (event, invoice) => {
    event.preventDefault();
    if (invoice?.id) {
      setActiveInvoice(invoice);
    }
  };

  const getItems = (page, filters) => {
    setFetchingData(true);
    axios
      .get("/api/list-invoices", {
        params: {
          page: page ?? 1,
          ...filters,
        },
      })
      .then((response) => {
        const apiResult = response?.data?.data;
        if (page > 1) {
          setInvoicesData((prev) => ({
            ...apiResult,
            data: [...prev?.data, ...apiResult?.data],
          }));
          return;
        }
        setInvoicesData(apiResult);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFetchingData(false);
        setSearching(false);
      });
  };

  useEffect(() => {
    getItems();
  }, []);

  if (fetchingData && !invoicesData)
    return <LoadingUI message={"Looking for result..."} />;

  return (
    <>
      <Container className="d-flex flex-column w-100 flex-fill py-4" fluid>
        <div className="mb-3">
          <p className="h5">Invoice</p>
          <p className="text-muted small">Manage invoices</p>

          <div className="d-flex flex-wrap justify-content-between align-items-center gap-1">
            <ComponentSearch value={searchKey} onChange={handleSearch} />
          </div>
        </div>

        <Card className="d-flex flex-column w-100 flex-fill">
          <Card.Body className="d-flex flex-column w-100 flex-fill p-0 bg-white">
            <Table
              className="theme-data-table small align-middle mb-0 flex-fill"
              striped
              bordered
              hover
              responsive
            >
              <thead className="text-muted text-uppercase small">
                <tr>
                  <th>S.no</th>
                  <th>Invoice number</th>
                  <th>Customer Name</th>
                  <th>Service</th>
                  <th>Amount</th>
                  <th>Discount</th>
                  <th>Subtotal</th>
                  <th>Paid</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {searching ? (
                  <TableLineItemLoading colspan={9} row={30} />
                ) : invoicesData?.total > 0 ? (
                  invoicesData?.data?.map((invoice, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td>
                          {invoice.invoice_number ? (
                            <Badge bg="success">{invoice.invoice_number}</Badge>
                          ) : (
                            <Badge bg="danger">Free version</Badge>
                          )}
                        </td>
                        <td>{invoice?.customer?.name}</td>
                        <td>{invoice.service}</td>
                        <td>
                          {invoice.currency_code} {invoice.price}
                        </td>
                        <td>
                          {invoice.currency_code} {invoice.discount}
                        </td>
                        <td>
                          {invoice.currency_code} {invoice.subtotal}
                        </td>
                        <td>
                          {invoice.is_paid ? (
                            <FaCheckCircle className="text-success" />
                          ) : invoice.plan !== "basic" ? (
                            <FaTimesCircle className="text-danger" />
                          ) : null}
                        </td>
                        <td>
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            <ShowButton
                              onClick={(event) => handleShow(event, invoice)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th colSpan={9} className="bg-warning text-black">
                      No results found
                    </th>
                  </tr>
                )}
              </tbody>
            </Table>

            {fetchingData ? (
              <div className="text-center small p-2">loading...</div>
            ) : invoicesData?.total > 0 &&
              invoicesData?.current_page !== invoicesData?.last_page ? (
              <ObserverTarget
                fetchData={() =>
                  getItems(invoicesData?.current_page + 1, {
                    name: searchKey,
                  })
                }
              />
            ) : null}
          </Card.Body>
          {!searching && invoicesData?.total > 0 ? (
            <Card.Footer className="border-0 bg-white">
              <p className="very-small m-0 text-center text-muted">
                Showing {invoicesData?.to} / {invoicesData.total}
              </p>
            </Card.Footer>
          ) : null}
        </Card>
      </Container>

      {activeInvoice?.id ? (
        <Offcanvas
          show
          onHide={() => setActiveInvoice(undefined)}
          placement="end"
          className="w-50 w-sm-100"
        >
          <Offcanvas.Header
            className="border-bottom fw-bold text-uppercase"
            closeButton
          >
            Invoice Details
          </Offcanvas.Header>
          <Offcanvas.Body className="d-flex flex-column">
            <InvoicePreview invoice={activeInvoice} enablePrint />
          </Offcanvas.Body>
        </Offcanvas>
      ) : null}
    </>
  );
};

export default Invoice;
