import React from "react";
import { Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

export default function DeleteButton({ onClick }) {
  return (
    <Button
      size="sm"
      variant="danger"
      className="m-1 box-shadow-none"
      title="Delete Item"
      onClick={onClick}
    >
      <FaTrash size={10} />
    </Button>
  );
}
