import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import ComponentSearch from "../../components/componentSearch/ComponentSearch";
import LoadingUI from "../../components/LoadingUI/LoadingUI";
import ThemeButton from "../../components/theme-button/ThemeButton";
import { FaPlus } from "react-icons/fa";
import DeleteButton from "../../components/buttons/deleteButton/DeleteButton";
import EditButton from "../../components/buttons/editButton/EditButton";
import StoreForm from "./StoreForm";
import TableLineItemLoading from "../../components/table-line-item-loading/TableLineItemLoading";
import ObserverTarget from "../../components/observerTarget/ObserverTarget";
import QrcodeDownloadButton from "../../components/buttons/qrcodeDownloadButton/QrcodeDownloadButton";
import CopyToClipBoardButton from "../../components/buttons/copyToClipBoardButton/CopyToClipBoardButton";
import defaultImage from "../../assests/images/default.jpg";

const Product = ({ listForAdmin }) => {
  const [warehouseData, setWarehouseData] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [show, setShow] = useState(false);
  const apiCallDelay = useRef(undefined);

  const handleModal = () => {
    setShow(!show);
    setEditItem({});
    setDeleteItem(null);
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    if (item) {
      setEditItem(item);
      setShow(true);
    }
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (id) {
      setDeleteItem(id);
      setShow(true);
    }
  };

  const handleSearch = (e) => {
    let query = e.target.value;
    setWarehouseData(undefined);
    setSearching(true);
    setSearchKey(query);
    apiCallDelay?.current && clearTimeout(apiCallDelay.current);
    apiCallDelay.current = setTimeout(() => {
      getItems(1, {
        name: query,
      });
    }, 1500);
  };

  const getItems = (page, filters) => {
    setFetchingData(true);
    axios
      .get("/api/store", {
        params: {
          page: page ?? 1,
          ...filters,
          ...(listForAdmin ? { listForAdmin: "1" } : null),
        },
      })
      .then((response) => {
        const apiResult = response?.data?.data;
        if (page > 1) {
          setWarehouseData((prev) => ({
            ...apiResult,
            data: [...prev?.data, ...apiResult?.data],
          }));
          return;
        }
        setWarehouseData(apiResult);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFetchingData(false);
        setSearching(false);
      });
  };

  useEffect(() => {
    getItems();
  }, []);

  if (fetchingData && !warehouseData)
    return <LoadingUI message={"Looking for stores..."} />;

  return (
    <Container className="d-flex flex-column w-100 flex-fill py-4" fluid>
      <div className="mb-3">
        <p className="h5">Warehouse</p>
        <p className="text-muted small">Manage your warehouses</p>

        <div className="d-flex flex-wrap justify-content-between align-items-center gap-1">
          <ComponentSearch value={searchKey} onChange={handleSearch} />
          {!listForAdmin && (
            <ThemeButton
              className="d-flex flex-row gap-1 align-items-center small"
              onClick={handleModal}
            >
              <FaPlus />
              <span>Add Warehouse</span>
            </ThemeButton>
          )}
        </div>
      </div>

      <StoreForm
        show={show}
        editItem={editItem}
        deleteItem={deleteItem}
        handleModal={handleModal}
        setResult={setWarehouseData}
      />

      <Card className="d-flex flex-column w-100 flex-fill">
        <Card.Body className="d-flex flex-column w-100 flex-fill p-0 bg-white">
          <Table
            className="theme-data-table small align-middle mb-0 flex-fill"
            striped
            bordered
            hover
            responsive
          >
            <thead className="text-muted text-uppercase small">
              <tr>
                <th>S.no</th>
                <th>Image</th>
                <th>Name</th>
                <th>Url Slug</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>Other Notes</th>
                {listForAdmin && <th>Owner</th>}
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {searching ? (
                <TableLineItemLoading colspan={listForAdmin ? 9 : 8} row={30} />
              ) : warehouseData?.total > 0 ? (
                warehouseData?.data?.map((store, index) => {
                  const storeImage = store.image_path
                    ? process.env.REACT_APP_API_BASE_URL.concat(
                        store.image_path
                      )
                    : defaultImage;
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>
                        <a
                          href={storeImage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={storeImage}
                            alt="store"
                            className="w-100 img-thumbnail"
                            loading="lazy"
                            style={{
                              maxWidth: "100px",
                            }}
                          />
                        </a>
                      </td>
                      <td>{store?.name}</td>
                      <td>{store?.slug}</td>
                      <td>{store?.contact_number}</td>
                      <td>{store?.address}</td>
                      <td>{store?.other_notes}</td>
                      {listForAdmin && <td>{store?.owner?.name}</td>}
                      <td>
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          {!listForAdmin && (
                            <>
                              <EditButton
                                onClick={(event) => handleEdit(event, store)}
                              />
                              <DeleteButton
                                onClick={(event) =>
                                  handleDelete(event, store?.id)
                                }
                              />
                            </>
                          )}
                          <QrcodeDownloadButton
                            fileName={store?.name}
                            url={process.env.REACT_APP_QR_CODE_URL.concat(
                              "store/",
                              store?.slug
                            )}
                          />
                          <CopyToClipBoardButton
                            url={process.env.REACT_APP_QR_CODE_URL.concat(
                              "store/",
                              store?.slug
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th
                    colSpan={listForAdmin ? 9 : 8}
                    className="bg-warning text-black"
                  >
                    No results found
                  </th>
                </tr>
              )}
            </tbody>
          </Table>

          {fetchingData ? (
            <div className="text-center small p-2">loading...</div>
          ) : warehouseData?.total > 0 &&
            warehouseData?.current_page !== warehouseData?.last_page ? (
            <ObserverTarget
              fetchData={() =>
                getItems(warehouseData?.current_page + 1, {
                  name: searchKey,
                })
              }
            />
          ) : null}
        </Card.Body>
        {!searching && warehouseData?.total > 0 ? (
          <Card.Footer className="border-0 bg-white">
            <p className="very-small m-0 text-center text-muted">
              Showing {warehouseData?.to} / {warehouseData.total}
            </p>
          </Card.Footer>
        ) : null}
      </Card>
    </Container>
  );
};

export default Product;
