import React from "react";
import { Button } from "react-bootstrap";
import { FaShare } from "react-icons/fa";
import { toast } from "react-toastify";

export default function CopyToClipBoardButton({ url }) {
  const handelCopyStoreURL = (e) => {
    e.preventDefault();
    if (typeof url !== "string") {
      throw new Error(
        "URL prop value is invalid, please provide a valid string"
      );
    }

    navigator.clipboard.writeText(url);
    toast.success("The store url is copied to your clipboard");
  };

  return (
    <Button
      size="sm"
      className="ms-1 box-shadow-none"
      variant="dark"
      title="Copy and share"
      onClick={handelCopyStoreURL}
    >
      <FaShare />
    </Button>
  );
}
