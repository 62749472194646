import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaBox, FaCubes, FaUsers, FaWarehouse } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import TileItem from "../../components/tile-item/TileItem";
import useAuth from "../../hooks/useAuth";

export default function DashboardMainPage() {
  const Auth = useAuth();
  const [fetching, setFetching] = useState(true);
  const [summeryData, setSummeryData] = useState(undefined);
  useEffect(() => {
    setFetching(true);
    axios
      .get("/api/dashboard/summery")
      .then((response) => {
        setSummeryData(response?.data?.data);
      })
      .catch((error) =>
        toast.error(error?.response?.data?.message ?? "Something went wrong!")
      )
      .finally(() => setFetching(false));
  }, []);

  return (
    <>
      <div>
        <p className="h5">Dashboard</p>
        <p className="text-muted small">
          Welcome back,{" "}
          <b className="text-dark">{Auth?.user?.name} &#128075;</b>
        </p>
      </div>

      <Row className="mt-4" xs={1} lg={3}>
        <Col className="d-flex mb-3">
          <TileItem
            isLoading={fetching}
            to="/dashboard/warehouse"
            icon={<FaWarehouse className="h6 m-0" />}
            iconContainerClassName="bg-info text-light"
            title="Warehouse"
            subTitle="Warehouse basic details"
            data={
              summeryData?.total_warehouse_count
                ? summeryData.total_warehouse_count + " Results found"
                : null
            }
          />
        </Col>
        <Col className="d-flex mb-3">
          <TileItem
            isLoading={fetching}
            to="/dashboard/inventory/category"
            icon={<FaCubes className="h6 m-0" />}
            iconContainerClassName="bg-danger text-light"
            title="Category"
            subTitle="Category basic details"
            data={
              summeryData?.total_category_count
                ? summeryData.total_category_count + " Results found"
                : null
            }
          />
        </Col>
        <Col className="d-flex mb-3">
          <TileItem
            isLoading={fetching}
            to="/dashboard/inventory/items"
            icon={<FaBox className="h6 m-0" />}
            iconContainerClassName="bg-success text-light"
            title="Items"
            subTitle="Items basic details"
            data={
              summeryData?.total_items_count
                ? summeryData.total_items_count + " Results found"
                : null
            }
          />
        </Col>
      </Row>

      {Auth?.isAdmin ? (
        <>
          <div>
            <p className="my-4 h6 text-muted">Admin Area</p>
          </div>
          <Row xs={1} lg={3}>
            <Col className="d-flex mb-3">
              <TileItem
                isLoading={fetching}
                to="/dashboard/admin/customers"
                icon={<FaUsers className="h6 m-0" />}
                iconContainerClassName="bg-info text-light"
                title="Customer"
                subTitle="Total customers"
                data={
                  summeryData?.admin_area_result?.total_customers_count
                    ? summeryData.admin_area_result.total_customers_count +
                      " Results found"
                    : null
                }
              />
            </Col>
            <Col className="d-flex mb-3">
              <TileItem
                isLoading={fetching}
                to="/dashboard/admin/warehouse"
                icon={<FaWarehouse className="h6 m-0" />}
                iconContainerClassName="bg-danger text-light"
                title="Warehouse"
                subTitle="Total warehouses"
                data={
                  summeryData?.admin_area_result?.total_warehouse_count
                    ? summeryData.admin_area_result.total_warehouse_count +
                      " Results found"
                    : null
                }
              />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
