import { useContext } from "react";
import { LoginContext } from "../contexts/LoginContext";

export default function useAuth() {
  const { user } = useContext(LoginContext);
  const isAdmin = user?.role === "admin" ? true : false;
  return {
    isAdmin,
    user,
  };
}
